<template>
    <div>

      <b-row>  
        <b-col class="col-12 col-lg-9">
          <b-col> 
            <mentionsTables :username="tiktokData.basic.username"/>
          </b-col>
          <b-col v-if="tiktokData.features.audience_languages.status.code === 'OK'">
            <features-one
              :languages="tiktokData.features.audience_languages.data"
              :countries="tiktokData.features.audience_geo.data.countries"
              :age_gender="(tiktokData.features.audience_age_gender.data !== null) ? tiktokData.features.audience_age_gender.data : {}"
              :audience_races="(tiktokData.features.audience_races.data !== null) ? tiktokData.features.audience_races.data : {}"
              :audience_by_type="(tiktokData.features.audience_by_type.data !== null) ? tiktokData.features.audience_by_type.data : {}"
              :audience_reachability="(tiktokData.metrics.audience_reachability !== null) ? tiktokData.metrics.audience_reachability : {}"
              :username="tiktokData.basic.username"
            />
          </b-col>
        </b-col>
        <b-col class="col-12 col-lg-3">
          <titktokRecommends
            :media="tiktokData.features.blogger_views_likes_chart.data"
            :username="tiktokData.basic.username"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
            <metrics 
              :subscribers_count="tiktokData.metrics.subscribers_count.performance"
              :subscribers_growth_prc="tiktokData.metrics.subscribers_growth_prc.performance"
              :er="tiktokData.metrics.er.performance"
              :alikes_avg="tiktokData.metrics.alikes_avg.performance"
            />
          </b-col>
          <b-col class="col-12">
            <features-two
              :blogger_prices="(tiktokData.features.blogger_prices.data !== null) ? tiktokData.features.blogger_prices.data : {} "
              :blogger_challenges_performance="tiktokData.features.blogger_challenges_performance.data.challenges_stats.performance"
              :likes_distribution="tiktokData.features.likes_distribution.data"
              :blogger_views_likes_chart="tiktokData.features.blogger_views_likes_chart.data"
              :channel_id="tiktokData.basic.id"
              :audience_sentiments="(tiktokData.features.audience_sentiments !== null) ? tiktokData.features.audience_sentiments : {}"
            />
          </b-col>
      </b-row>
    </div>
</template>
<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import featuresOne from './featuresOne.vue'
import featuresTwo from './featuresTwo.vue'
import metrics from './metrics.vue'
import titktokRecommends from './titktokRecommends.vue'
import mentionsTables from './mentionsTables.vue'

export default {
  components: {
    metrics,
    featuresTwo,
    titktokRecommends,
    BRow,
    BCol,
    featuresOne,
    mentionsTables
  },
  props: {
    tiktokData: {
      type: Object,
      required: true
    }
  }
}
</script>
