<template>
  <b-row class="p-0">
    <b-col cols="12" v-if="getData().length > 0">
      <b-card class="p-0">
        <b-card-header>
          <b-card-title>{{$t('tiktok.tiktokRecom')}}</b-card-title>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col class="col-12 col-sm-6 col-lg-12 p-0" v-for="tiktok in getData().slice(0,4)" :key="tiktok.id">
              <tiktok-card :id="tiktok.id" :username="username"></tiktok-card>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import {BCol, BRow, BCardHeader, BCardBody, BCard, BCardTitle} from 'bootstrap-vue'
import TiktokCard from './../TiktokCard.vue'
export default {
  components: {
    BCol,
    BRow,
    BCardHeader,
    BCardBody,
    BCard,
    BCardTitle,
    TiktokCard,
    
  },
  methods: {
    getData() {
      return this.media;
    }
  },
  props: {
    media: {
      type: Array,
      required: true
    },
    username: {
      type: String,
      required: true
    },
  },
}
</script>
