<template>
      <b-row>
        <b-col class="d-flex align-items-stretch col-12 col-md-6 col-lg-4">
          <b-card>
            <b-card-header>
                <b-card-title class="ml-1">{{$t('instagram.increaseFollowers')}}</b-card-title>
            </b-card-header>
            <b-card-body>
                <b-row class="d-flex flex-wrap">
                <b-col class="col-12 mb-2">
                  <vue-apex-charts type="line" height="350" :options="subscribersCountOptions" :series="subscribersCountSeries"/>
                </b-col>
                  <b-col class="col-12 d-flex flex-wrap">
                      <div class="mb-4 col-12 col-md-6" v-for="(item, index) in getSubscribersGrowthPrc().categories" :key="index">
                      <div class="col-12 d-flex align-items-center flex-wrap">
                          <span :class="`dot-profile-tiktok mb-1 ${utils.getColorDotProfile(getSubscribersGrowthPrc().value[index].mark)}`"/> 
                          <span class="h3 d-block mr-1">{{getSubscribersGrowthPrc().value[index].value}} %</span>
                          <span>{{$t(`tiktok.${getSubscribersGrowthPrc().value[index].mark}`)}}</span>
                          <span class="col-12 mb-1">
                          {{(index !== getSubscribersGrowthPrc().categories.length - 1) ? $t('twitter.last') : ''}} 
                          {{getSubscribersGrowthPrc().categories[index]}}
                          </span>
                          <span v-if="getSubscribersGrowthPrc().value[index].similar !== 0" class="col-12 text-muted">
                          {{$t('tiktok.accounts_similar')}} 
                          <strong>{{getSubscribersGrowthPrc().value[index].similar.toFixed(2)}} %</strong>
                          </span>
                      </div>
                      </div>
                  </b-col>
                </b-row>
            </b-card-body>
          </b-card>
        </b-col>

        <b-col class="col-12 col-md-6 col-lg-4 d-flex align-items-stretch">
          <b-card>
            <b-card-header>
              <b-card-title class="ml-1">Engagement</b-card-title>
            </b-card-header>
            <b-card-body>
              <b-row class="d-flex flex-wrap">
                <b-col class="col-12 mb-2">
                  <vue-apex-charts type="line" height="350" :options="erOptions" :series="erSeries" />
                </b-col>
                <b-col class="col-12 d-flex flex-wrap">
                  <div class="mb-4 col-12 col-md-6" v-for="(item, index) in getDataAsideEr()" :key="index">
                    <div>
                      <p class="d-flex align-items-center flex-wrap">
                          <span :class="`dot-profile-tiktok mb-1 ${utils.getColorDotProfile(item.mark)}`"/> 
                          <span class="h3 d-block mr-1">{{item.value}} % </span>
                          <span class="h">{{$t(`tiktok.${item.mark}`)}}</span>
                      </p>
                      <p class="col-12">
                        {{$t('twitter.last')}}
                        {{getErCategories()[index]}}
                      </p>
                      <p v-if="item.similar !== 0" class="col-12 text-muted">
                        {{$t('tiktok.accounts_similar_er')}} 
                        <strong>{{utils.decimalFixed(item.similar, 2)}} %</strong>
                      </p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>

        <b-col class="col-12 col-md-6 col-lg-4 d-flex align-items-stretch">
          <b-card>
            <b-card-header>
              <b-card-title class="ml-1">{{$t('tiktok.alikes_avg_title')}}</b-card-title>
            </b-card-header>
            <b-card-body>
              <b-row class="d-flex flex-wrap">
                <b-col class="col-12 mb-2">
                  <vue-apex-charts type="line" height="350" :options="alikesAvgOptions" :series="alikesAvgSeries"/>
                </b-col>
                <b-col class="col-12 d-flex flex-wrap">
                  <div class="mb-2 col-12 col-md-6" v-for="(item, index) in getAlikesAvgSeries()" :key="index">
                    <div>
                      <p>
                        <span>{{$t('tiktok.alikes_text')}} {{getAlikesAvgCategories()[index]}} {{$t('tiktok.is_of')}} <strong>{{utils.getFormat(item)}}</strong>.</span>
                      </p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
</template>
<script>
import { BRow, BCol, BCard, BCardHeader, BCardTitle, BCardBody } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import utils from '@/libs/utils'

export default {
  name: 'featuresOne',
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    VueApexCharts,
  },
  props: {
    subscribers_count: {
      type: Object,
      required: true,
    },
    subscribers_growth_prc: {
      type: Object,
      required: true
    },
    er: {
      type: Object,
      required: true
    },
    alikes_avg: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      utils,
    }
  },
  methods: {
    getSubscribersCountSeries() {
      return Object.values(this.subscribers_count).map((item) => item.value);
    },
    getCategoriesSubscribersCount() {
      const new_categories = Object.keys(this.subscribers_count).map((item) => {
        return `${item.slice(0, -1)} ${this.$t('twitter.days')}`;
      })
      return new_categories;
    },
    getSubscribersGrowthPrc() {
      const categories = Object.keys(this.subscribers_growth_prc).map((item) => {
        return (item === '365d') ? this.$t('tiktok.last_year') :  `${item.slice(0, -1)} ${this.$t('twitter.days')}`
      });
      const value = Object.values(this.subscribers_growth_prc);
      return {categories, value}
    },
    getErSerie() {
      return Object.values(this.er).map((item) => item.value);
    },
    getErCategories() {
      return Object.keys(this.er).map((item) => `${item.slice(0, -1)} ${this.$t('twitter.days')}`)
    },
    getDataAsideEr() {
      return Object.values(this.er);
    },
    getAlikesAvgSeries() {
      return Object.values(this.alikes_avg).map((item) => item.value);
    },
    getAlikesAvgCategories() {
      return Object.keys(this.alikes_avg).map((item) => `${item.slice(0, -1)} ${this.$t('twitter.days')}`)
    },
  },
  computed: {
    subscribersCountOptions() {
      return {
        tooltip: {
          custom: ({ seriesIndex, dataPointIndex, w}) => {
            return (
              `<div class="width-tooltip-graph">
                  <div class="d-block bg-toottip-graph p-1">
                   ${this.$t('tiktok.growth_followers')} ${this.getCategoriesSubscribersCount()[dataPointIndex]}
                  </div>
                  <div class="p-1">
                    <strong class="mr-tooltip-graph">${this.$t('campaigns.followers')}: 
                    </strong> ${utils.getFormat(w.globals.initialSeries[seriesIndex].data[dataPointIndex])}
                  </div>
                </div>`
            )
          }
        },
        chart: {
          zoom: {
            enabled: true,
          },
          toolbar: {
            show: false,
          },
          type: "line",
          dropShadow: {
            enabled: true,
            top: 18,
            left: 2,
            blur: 5,
            opacity: 0.2,
          },
          offsetX: -10,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 4,
        },
        grid: {
          borderColor: "#ebe9f1",
          padding: {
            top: -20,
            bottom: 5,
            left: 20,
          },
        },
        legend: {
          show: false,
        },
        title: {
          text: "",
          align: "left",
        },
        colors: ["#f0b30d"],
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            inverseColors: false,
            gradientToColors: ["#f24e0d"],
            shadeIntensity: 1,
            type: "horizontal",
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100],
          },
        },
        markers: {
          size: 0,
          hover: {
            size: 5,
          },
        },
        yaxis: {
          labels: {
            formatter: (value) => {
              return `${utils.getFormat(value)}` 
            },
          }
        },
        xaxis: {
          labels: {
            offsetY: 5,
            style: {
              colors: "#b9b9c3",
              fontSize: "0.857rem",
            },
          },
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: false,
          },
          categories: this.getCategoriesSubscribersCount()
        },
      }
    },
    subscribersCountSeries() {
      return [
        {
          data: this.getSubscribersCountSeries(),
        },
      ]
    },
    erOptions() {
      return {
        tooltip: {
          custom: ({ seriesIndex, dataPointIndex, w}) => {
            return (
              `<div class="width-tooltip-graph">
                  <div class="d-block bg-toottip-graph p-1">
                   ${this.$t('twitter.engagement_rate')} ${this.getErCategories()[dataPointIndex]}
                  </div>
                  <div class="p-1">
                    <strong class="mr-tooltip-graph">${this.$t('twitter.engagementRate')}: 
                    </strong> ${utils.separatebycomma(w.globals.initialSeries[seriesIndex].data[dataPointIndex])} %
                  </div>
                </div>`
            )
          }
        },
        chart: {
          zoom: {
            enabled: true,
          },
          toolbar: {
            show: false,
          },
          type: "line",
          dropShadow: {
            enabled: true,
            top: 18,
            left: 2,
            blur: 5,
            opacity: 0.2,
          },
          offsetX: -10,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 4,
        },
        grid: {
          borderColor: "#ebe9f1",
          padding: {
            top: -20,
            bottom: 5,
            left: 20,
          },
        },
        legend: {
          show: false,
        },
        title: {
          text: "",
          align: "left",
        },
        colors: ["#f0b30d"],
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            inverseColors: false,
            gradientToColors: ["#f24e0d"],
            shadeIntensity: 1,
            type: "horizontal",
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100],
          },
        },
        markers: {
          size: 0,
          hover: {
            size: 5,
          },
        },
        xaxis: {
          labels: {
            offsetY: 5,
            style: {
              colors: "#b9b9c3",
              fontSize: "0.857rem",
            },
          },
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: false,
          },
          categories: this.getErCategories()
        },
      }
    },
    erSeries() {
      return [
        {
          data: this.getErSerie(),
        },
      ]
    },
    alikesAvgOptions() {
      return {
        tooltip: {
          custom: ({ seriesIndex, dataPointIndex, w}) => {
            return (
              `<div class="width-tooltip-graph">
                  <div class="p-1">
                    <strong class="mr-tooltip-graph"> ${this.$t('tiktok.alikes_avg_title')}: 
                    </strong> ${utils.getFormat(w.globals.initialSeries[seriesIndex].data[dataPointIndex])}
                  </div>
                </div>`
            )
          }
        },
        chart: {
          zoom: {
            enabled: true,
          },
          toolbar: {
            show: false,
          },
          type: "line",
          dropShadow: {
            enabled: true,
            top: 18,
            left: 2,
            blur: 5,
            opacity: 0.2,
          },
          offsetX: -10,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 4,
        },
        grid: {
          borderColor: "#ebe9f1",
          padding: {
            top: -20,
            bottom: 5,
            left: 20,
          },
        },
        legend: {
          show: false,
        },
        title: {
          text: "",
          align: "left",
        },
        colors: ["#f0b30d"],
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            inverseColors: false,
            gradientToColors: ["#f24e0d"],
            shadeIntensity: 1,
            type: "horizontal",
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100],
          },
        },
        markers: {
          size: 0,
          hover: {
            size: 5,
          },
        },
        yaxis: {
          labels: {
            formatter: (val) => {
              return utils.getFormat(val)
            },
          }
        },
        xaxis: {
          labels: {
            offsetY: 5,
            style: {
              colors: "#b9b9c3",
              fontSize: "0.857rem",
            },
          },
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: false,
          },
          categories: this.getAlikesAvgCategories()
        },
      }
    },
    alikesAvgSeries() {
      return [
        {
          data: this.getAlikesAvgSeries(),
        },
      ]
    },
  }
}
</script>
